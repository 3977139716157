import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import ContainerText from '../../components/container_text'
import SEO from '../../components/seo'
import ShopifyPage from '../../components/shopify_page';

const Title = styled.h3`
  padding-bottom: 2rem;
`

const TermsContainer = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  p {
    font-style: italic;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  }
`

const IndexPage = () => (
  <div>
    <SEO title="Terms & Conditions" keywords={[`Terms`, `Conditions`,`Theodora`, `Warre`]} />
    <ContainerText longpage>
      <Container>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12">

            <TermsContainer>

            <Title>Terms of service</Title>

            <ShopifyPage handle="terms" />

            </TermsContainer>

          </Col>
        </Row>
      </Container>
    </ContainerText>
  </div>
)

export default IndexPage
